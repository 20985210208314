import {isUndefined, isNull, keys, isString, isArray} from 'lodash';

const isValidObj = obj => {
  if (isUndefined(obj)) return false;
  else if (isNull(obj)) return false;
  else if (typeof obj === 'object' && keys(obj).length === 0) return false;
  else return true;
};

const isValidArray = array => {
  return (
    !isUndefined(array) &&
    !isNull(array) &&
    isArray(array) &&
    array.length !== 0
  );
};

const isValidString = string => {
  return (
    !isUndefined(string) &&
    !isNull(string) &&
    isString(string) &&
    string !== '' &&
    string !== 'null'
  );
};

const isValidCoordinate = coordinate => {
  return (
    !isUndefined(coordinate) &&
    !isNull(coordinate) &&
    coordinate !== '' &&
    coordinate !== 'null'
  );
};

export {isValidObj, isValidArray, isValidString, isValidCoordinate};
