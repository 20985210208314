import clsx from 'clsx';
import { includes, map, toLower } from 'lodash';
import LocaleService from '@root/services/locale';
import React, { useEffect, useState } from 'react';
import NextAvailDelService from '@root/services/nextAvailDelivery';

import styles from './styles.module.scss';
import FormRadio from '../FormRadio';
import BButton from '../BButton';
import ExpressDelivery from '../ExpressDelivery';

function Picker({onDone}) {
  const {t} = LocaleService.useLocale();
  const [search, setSearch] = useState('');
  const [selectedCity, setSelectedCity] = useState(null); // temp for user action feeback
  const {locData, locRegion, locCity} = NextAvailDelService.useLocationData();

  useEffect(() => {
    setSelectedCity(locCity);
  }, [locCity])

  const onChangeSearch = (event) => {
    setSearch( event.target.value);
  }

  const onClickRegion = (id) => {
    NextAvailDelService.fetchNSetData({regionId: id})
    .catch(error=>console.log(error));
  }

  const onSelectCity = (city) => {
    setSelectedCity(city);
  }

  const onClickAction = () => {
    NextAvailDelService.fetchNSetData({regionId: locRegion?.key, cityId: selectedCity?.key})
    .catch(error=>console.log(error))
    .finally(onDone)
  }

  return (
    <div className={styles.locpicker}>
      <div className={styles.locpicker_stick}>
        <div className={styles.locpicker_head}>
          <h4 className={styles.locpicker_heading}>{t('select_location')}</h4>
        </div>
        <div className={styles.regions}>
          {map(locData?.regions?.data, region => {
            let isActive = locRegion?.key === region.key;
            return (
              <a 
                key={region.key}
                onClick={() => onClickRegion(region.key)} 
                className={clsx(styles.regions_link, isActive && styles.active)}>
                  {region?.label}
              </a>
            )
          })}
        </div>
        <div className={styles.search}>
          <input 
            type='text' 
            className={styles.search_input} 
            placeholder={t('br_search')}
            onChange={onChangeSearch}/>
        </div>
      </div>

      <div className={styles.cities}>
        <div className={styles.cities_list}>
          {map(locRegion?.city, city => {
            let isSelected = city?.key === selectedCity?.key
            if(!includes(toLower(city?.label), toLower(search))) return;
            else return (
              <div key={city?.key} className={styles.cities_item}>
                <FormRadio label={city?.label} selected={isSelected} onChange={()=>onSelectCity(city)} />
                {city?.express_delivery_available && <ExpressDelivery size={7} />}
              </div>
            )
          })}
        </div>
        <div className={styles.locpicker_action}>
          <BButton className={styles.locpicker_button} label={t('br_done')} onPress={onClickAction} />
        </div>
      </div>
    </div>
  )
}

export default Picker