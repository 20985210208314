import { isNull, get } from "lodash";
import { isValidObj, isValidString } from "@root/libs/utils";

class Parser {
  constructor() {
    this.messages = {
      200: "",
      400: "Client issued a bad request.",
      401: "you session has expired or invalid.",
      403: "You dont have permission to access the resource.",
      404: "Requested resource not found",
      500: "Server encountered an error",
      UNKNOWN_ERROR: "Something went wrong",
      TIMEOUT_ERROR: "Server didn't respond in time.",
      CONNECTION_ERROR: "Server not available, bad dns.",
      NETWORK_ERROR: "Network not available.",
      CANCEL_ERROR: "Request has been cancelled.",
    };

    if (!this.instance) this.instance = this;
    return this.instance;
  }

  _customMessage = (key) => {
    return this.messages[key] ? this.messages[key] : "";
  };

  parseResponse = (response) => {
    if (get(response, ['ok'], false)) {
      return this._okResponse(response.status, response.data);
    } else if (!isNull(response.status)) {
      return this._errorResponse(response.status, response.data);
    } else {
      return this._errorResponse(response.problem);
    }
  };

  _okResponse = (status, data = {}) => {
    let message = isValidString(data.message)
      ? data.message
      : this._customMessage(status);
    return {
      ok: true,
      code: status,
      errors: null,
      message: message,
      data: data.data ? data.data : data,
    };
  };

  _errorResponse = (status, data = {}) => {
    let message = isValidObj(data.message)
      ? data.message
      : this._customMessage(status);
    return {
      ok: false,
      data: null,
      code: status,
      message: message,
      errors: data.errors ? data.errors : data,
    };
  };
}
const ParserService = new Parser();
export default ParserService;
