import {find, get} from 'lodash';
import config from '@root/config';
import QuoteService from './quote';
import { useEffect, useState } from 'react';
import AuthService from '@root/services/auth';
import {of, catchError, BehaviorSubject, firstValueFrom, switchMap} from 'rxjs';

import LocaleService from './locale';
import RequestService from './request';

class NextAvailDelivery {
  constructor() {
    this.$behaviourData = new BehaviorSubject({});
    this.$behaviourRegion = new BehaviorSubject({});
    this.$behaviourCity = new BehaviorSubject({});

    this.fetchNSetData({}).catch(error=>console.log(error));
    if (!this.instance) this.instance = this;
    return this.instance;
  }

  get findRegion() {
    let value = this.$behaviourData.getValue();
    let regions = get(value, ['regions', 'data'], []);
    let regionId = get(value, ['regions', 'value'], null);
    return find(regions, {key: regionId});
  }

  get findCity() {
    let value = this.$behaviourRegion.getValue();
    let cities = get(value, ['city'], []);
    let cityId = get(value, ['value'], null);
    return find(cities, {key: cityId});
  }

  get timeSlot() {
    let value = this.$behaviourData.getValue();
    return get(value, ['next'], {});
  }

  useLocationData() {
    const [locData, setLocData] = useState({});
    const [locCity, setLocCity] = useState({});
    const [locRegion, setLocRegion] = useState({});

    useEffect(() => {
      let dataSub = this.$behaviourData.subscribe(setLocData);
      let regionSub = this.$behaviourRegion.subscribe(setLocRegion);
      let citySub = this.$behaviourCity.subscribe(setLocCity);

      return () => {
        dataSub.unsubscribe();
        regionSub.unsubscribe();
        citySub.unsubscribe();
      }
    }, []);

    return {
      locData,
      locRegion,
      locCity,
    }
  }

  deliveryData(value) {
    this.$behaviourRegion.next(value);
  }

  fetchNSetData({regionId, cityId}) {
    return firstValueFrom(this.fetchNextDel({regionId: regionId, cityId: cityId})).then((result) => {
      this.$behaviourData.next(result?.data);
      this.$behaviourRegion.next(this.findRegion);
      this.$behaviourCity.next(this.findCity);
      return Promise.resolve({});
    });
  }

  fetchNextDel({regionId, cityId}) {
    return QuoteService.$quote.pipe(
      switchMap(quote => {
        let param = `${regionId ? regionId : '0'}-${cityId ? cityId : '0'}-${quote ? quote : '0'}`;
        return this.nextDeliveryRequest(param).pipe(catchError(error => of(error)));
      })
    );
  }

  nextDeliveryRequest = (param) => {
    return AuthService.isAuth ? 
    RequestService.$authGet(`${config.base_path}${LocaleService.current}/rest/V1/customapi/next_delivery_date/${param}`) :
    RequestService.$get(`${config.base_path}${LocaleService.current}/rest/V1/customapi/guest/next_delivery_date/${param}`)
  }
}
const NextAvailDelService = new NextAvailDelivery;
export default NextAvailDelService;
