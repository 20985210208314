import {find, get} from 'lodash';
import config from '@root/config';
import TokenService from './token';
import LoggerService from './logger';
import LocaleService from './locale';
import RequestService from './request';
import TrackingService from './tracking';
import {isValidString} from '@root/libs/utils';
import {BehaviorSubject, merge, of} from 'rxjs';
import {map as rxmap, switchMap} from 'rxjs/operators';
import EventsService, {ADDRESSES_MODIFIED} from './events';

class User {
  constructor() {
    this.requestCall = null;
    this.$behaviorUser = new BehaviorSubject(null);

    // refetch user data on events
    merge(EventsService.$event(ADDRESSES_MODIFIED))
      .pipe(switchMap(() => this.userData()))
      .subscribe({
        next: result => {
          this.$behaviorUser.next(result);
          TrackingService.setTrackingData('user', result);
        },
        error: {
          /** TODO: habdle error for this case */
        },
      });

    TokenService.$token
      .pipe(
        switchMap(token => {
          if (!isValidString(token)) return of(null);
          else return this.userData();
        }),
      )
      .subscribe({
        next: result => {
          this.$behaviorUser.next(result);
          TrackingService.setTrackingData('user', result);
        },
        error: error => {
          /**
           * to avoid cyclic dependency
           * using token service method to logout
           */
          TokenService.clear();
          LoggerService.logError('Error user service init data', error);
        },
      });

    if (!this.instance) this.instance = this;
    return this.instance;
  }

  get $user() {
    return this.$behaviorUser.asObservable();
  }

  get userid() {
    let user = this.$behaviorUser.getValue();
    return user.id;
  }

  get addresses() {
    let user = this.$behaviorUser.getValue();
    return user?.addresses;
  }

  set addresses(list) {
    let user = this.$behaviorUser.getValue();
    user.addresses = list;
    this.$behaviorUser.next(user);
  }

  get firstname() {
    let user = this.$behaviorUser.getValue();
    return user?.firstname;
  }

  get lastname() {
    let user = this.$behaviorUser.getValue();
    return user?.lastname;
  }

  get email() {
    let user = this.$behaviorUser.getValue();
    return user?.email;
  }

  get mobile() {
    let data = this.$behaviorUser.getValue();
    let attributes = data?.custom_attributes;
    let found = find(attributes, {attribute_code: 'mobilenumber'});
    return found?.value;
  }

  set(data) {
    this.$behaviorUser.next(data);
  }

  updatePassword(id, data) {
    return RequestService.$authPut(
      `${config.base_path}${LocaleService.current}/rest/V1/customers/me/password?customerId=${id}`,
      {body: data},
    );
  }

  updateProfile(data) {
    return RequestService.$authPut(
      `${config.base_path}${LocaleService.current}/rest/V1/customapi/customer/profile/update`,
      {body: data},
    );
  }

  userData() {
    return RequestService.$authGet(
      `${config.base_path}${LocaleService.current}/rest/V1/customers/me/`,
    ).pipe(rxmap(result => get(result, ['data'], null)));
  }
}
const UserService = new User();
export default UserService;
