import PopModal from '@root/common/PopModal';
import React, { useEffect, useRef } from 'react';
import EventsService, { PICK_LOCATION } from '@root/services/events';

import DeviceView from '../DeviceView';
import BottomSheet from '../BottomSheet';
import Picker from './picker';

function LocationPicker() {
  const modalRef = useRef(null);

  useEffect(() => {
    let subscription = EventsService.$event(PICK_LOCATION).subscribe(() => {
      modalRef.current.show();
    })
    return () => {
      subscription.unsubscribe();
    }
  }, []);

  const onDoneLocation = () => {
    modalRef.current.hide();
  }

  return (
    <DeviceView 
      mobile={<BottomSheet ref={modalRef}><Picker onDone={onDoneLocation} /></BottomSheet>}
      desktop={<PopModal ref={modalRef} bgaction><Picker onDone={onDoneLocation} /></PopModal>}
    />
  );
}

export default LocationPicker;
